<template>
  <div>
    <h3>Reporting</h3>
    <el-tabs v-model="activeTab">
      <el-tab-pane
        v-if="hasPermission('audience_publication_reports')"
        label="Audience Publications"
        name="audiencePublications"
      >
        <audience-publication-report v-if="activeTab === 'audiencePublications'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasPermission('audience_creation_reports')"
        label="Audience Creation"
        name="audienceCreation"
      >
        <audience-creation-report v-if="activeTab === 'audienceCreation'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasPermission('attribution_usage_reports')"
        label="Attribution Usage"
        name="attribution"
      >
        <attribution-usage-report v-if="activeTab === 'attribution'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasPermission('geoframe_creation_reports')"
        label="Geoframe Creation"
        name="geoframeCreation"
      >
        <geoframe-creation-report v-if="activeTab === 'geoframeCreation'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasPermission('eula_reports')"
        label="EULA Acceptance"
        name="eulaAcceptance"
      >
        <eula-report v-if="activeTab === 'eulaAcceptance'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="canViewActivity"
        label="Activity"
        name="activity"
      >
        <user-activity-report v-if="activeTab === 'activity'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import {
  attributionUsageReport,
  audienceCreationReport,
  audiencePublicationReport,
  geoframeCreationReport,
  userActivityReport,
  EulaReport,
} from './reports';

import { mapGetters } from 'vuex';

export default {
  components: {
    attributionUsageReport,
    audienceCreationReport,
    audiencePublicationReport,
    geoframeCreationReport,
    userActivityReport,
    EulaReport,
  },

  data() {
    return {
      activeTab: 'audiencePublications',
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission', 'canViewActivity']),
  },
};
</script>
