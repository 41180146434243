<template>
  <div v-loading.fullscreen.lock="busy">
    <div>
      <date-picker
        class="date-picker"
        :date-range.sync="dateRange"
        open-start-date
        :to="new Date()"
      />
      <el-button
        type="info"
        :loading="preparingDownload"
        @click="downloadCsv"
      >
        <i class="fa fa-file-spreadsheet" /> Download .csv
      </el-button>
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :find-options="findOptions"
      :data-class="reportModel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      disable-checkboxes
      disable-action-menu
      disable-search
    >
      <el-table-column
        prop="audience"
        label="Audience"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <audience-type-tooltip :type="row.a_type" />
          &nbsp;
          {{ row.a_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="extension"
        label="Extension"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.p_extension ? 'Yes' : 'No' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="extension_type"
        label="Extension Type"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.p_extension_type }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isInternal"
        prop="parent"
        label="Parent Organization"
      >
        <template slot-scope="{ row }">
          {{ row.parent ? row.parent : 'N/A' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.o_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user"
        label="Author"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.u_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="audience_type"
        label="Type"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.a_type | audienceTypes }}
        </template>
      </el-table-column>
      <el-table-column
        prop="publish_count"
        label="Count"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span v-if="row.p_count">{{ row.p_count | numeral }}</span>
          <span v-else>N/A</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="partner"
        label="Partner"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.pp_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.p_status }}
        </template>
      </el-table-column>
      <el-table-column
        prop="started"
        label="Started"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.p_started | dateToString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="completed"
        label="Completed"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.p_completed | dateToString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="recur_type"
        label="Recur Type"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span v-if="!row.ps_recur_type">N/A</span>
          <span v-else>{{ row.ps_recur_type }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Initial Publish"
      >
        <template slot-scope="{ row }">
          <span v-if="!row.ps_config">N/A</span>
          <span v-else>{{ row.ps_config.from.substr(0, 10) === row.ps_scheduled.substr(0, 10) ? 'Yes' : 'No' }}</span>
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { audienceTypes, dateToString } from '@/helpers';
import { publicationReport } from '@/adonis-api';
import downloadFile from '@/helpers/download-file';
import AudienceTypeTooltip from '@/components/global/AudienceTypeTooltip.vue';
import DataTable from '@/components/global/DataTable.vue';
import DatePicker from '@/components/global/DatePicker.vue';

import moment from 'moment';

export default {
  components: { AudienceTypeTooltip, DataTable, DatePicker },
  filters: { audienceTypes, dateToString },

  data() {
    return {
      busy: false,
      dateRange: [
        moment().utc().subtract(1, 'week'),
        moment().utc(),
      ],
      dataTable: {
        actions: [],
        order: 'descending',
        sortBy: 'started',
      },
      preparingDownload: false,
      reportModel: null,
    };
  },

  computed: {
    ...mapGetters('user', ['isInternal']),

    findOptions() {
      return {
        start: moment(this.dateRange[0]).utc().format('YYYY-MM-DD'),
        end: moment(this.dateRange[1]).utc().format('YYYY-MM-DD'),
      };
    },
  },

  created() {
    this.reportModel = publicationReport;
  },

  methods: {
    async downloadCsv() {
      this.preparingDownload = true;

      const result = await publicationReport.find({ ...this.findOptions, csv: true });

      downloadFile(result, {
        filename: `Audience-Publication-Report-${new Date().toISOString().slice(0, 10)}.csv`,
        mimeType: 'text/csv',
        prettify: false,
      });

      this.preparingDownload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.date-picker {
  display: inline-block;
  margin: 0 1em 1em 0;
  position: relative;
  top: 1px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}

.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
