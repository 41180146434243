<template>
  <div v-loading.fullscreen.lock="busy">
    <div>
      <el-button
        type="info"
        :loading="preparingDownload"
        @click="downloadCsv"
      >
        <i class="fa fa-file-spreadsheet" /> Download .csv
      </el-button>
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="reportModel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      disable-checkboxes
      disable-action-menu
      disable-search
    >
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.first_name }} {{ row.last_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.organization ? row.organization.name : 'N/A' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.email }}
        </template>
      </el-table-column>
      <el-table-column
        prop="tos_acceptance"
        label="EULA Acceptance Date"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.tos_acceptance | dateToString }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { dateToString } from '@/helpers';
import { eulaReport } from '@/adonis-api';
import downloadFile from '@/helpers/download-file';
import DataTable from '@/components/global/DataTable.vue';

export default {
  components: { DataTable },
  filters: { dateToString },

  data() {
    return {
      busy: false,
      dataTable: {
        actions: [],
        order: 'descending',
        sortBy: 'tos_acceptance',
      },
      preparingDownload: false,
      reportModel: null,
    };
  },

  created() {
    this.reportModel = eulaReport;
  },

  methods: {
    async downloadCsv() {
      this.preparingDownload = true;

      const result = await eulaReport.find({ csv: true });

      downloadFile(result, {
        filename: `EULA-Acceptance-Report-${new Date().toISOString().slice(0, 10)}.csv`,
        mimeType: 'text/csv',
        prettify: false,
      });

      this.preparingDownload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}

.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
