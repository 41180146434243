<template>
  <span>
    <el-tooltip
      class="item"
      effect="light"
      :content="`${audienceType(type)}`"
    >
      <i
        :class="audienceTypeClass(type)"
        class="fa fa-fw"
      />
    </el-tooltip>
  </span>
</template>

<script>
import { audienceTypes, audienceTypeClasses } from '@/helpers';

export default {
  props: {
    type: {
      type: String,
      default: 'GEOFRAME',
    },
  },

  methods: {
    audienceType(type) {
      if (type === 'PUBLICATION') return 'Publication';
      return audienceTypes(type) + ' Audience';
    },

    audienceTypeClass(type) {
      if (type === 'PUBLICATION') return 'fa-paper-plane';
      return audienceTypeClasses(type);
    },
  },

};
</script>
