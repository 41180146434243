<template>
  <div v-loading.fullscreen.lock="busy">
    <div>
      <date-picker
        class="date-picker"
        :date-range.sync="dateRange"
        open-start-date
        :to="new Date()"
      />
      <el-button
        type="info"
        :loading="preparingDownload"
        @click="downloadCsv"
      >
        <i class="fa fa-file-spreadsheet" /> Download .csv
      </el-button>
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :find-options="findOptions"
      :data-class="reportModel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      disable-checkboxes
      disable-action-menu
      disable-search
    >
      <el-table-column
        prop="name"
        label="Report Title"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.ar_name }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isInternal"
        prop="parent"
        label="Parent Organization"
      >
        <template slot-scope="{ row }">
          {{ row.parent ? row.parent : 'N/A' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.o_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="source_count"
        label="Campaign Devices"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.ar_source_count | numeral }}
        </template>
      </el-table-column>
      <el-table-column
        prop="destination_count"
        label="Destination Devices"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.ar_destination_count | numeral }}
        </template>
      </el-table-column>
      <el-table-column
        prop="deleted_at"
        label="Archived"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span v-if="row.ar_deleted_at">{{ row.ar_deleted_at | dateToString }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.ar_created_at | dateToString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="author"
        label="Author"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.author }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { audienceTypes, dateToString } from '@/helpers';
import { attributionReport } from '@/adonis-api';
import downloadFile from '@/helpers/download-file';
import DataTable from '@/components/global/DataTable.vue';
import DatePicker from '@/components/global/DatePicker.vue';

import moment from 'moment';

export default {
  components: { DataTable, DatePicker },
  filters: { audienceTypes, dateToString },

  data() {
    return {
      busy: false,
      dateRange: [
        moment().utc().subtract(1, 'week'),
        moment().utc(),
      ],
      dataTable: {
        actions: [],
        order: 'descending',
        sortBy: 'created_at',
      },
      preparingDownload: false,
      reportModel: null,
    };
  },

  computed: {
    ...mapGetters('user', ['isInternal']),

    findOptions() {
      return {
        start: moment(this.dateRange[0]).utc().format('YYYY-MM-DD'),
        end: moment(this.dateRange[1]).utc().format('YYYY-MM-DD'),
      };
    },
  },

  created() {
    this.reportModel = attributionReport;
  },

  methods: {
    async downloadCsv() {
      this.preparingDownload = true;

      const result = await attributionReport.find({ ...this.findOptions, csv: true });

      downloadFile(result, {
        filename: `Attribution-Usage-Report-${new Date().toISOString().slice(0, 10)}.csv`,
        mimeType: 'text/csv',
        prettify: false,
      });

      this.preparingDownload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.date-picker {
  display: inline-block;
  margin: 0 1em 1em 0;
  position: relative;
  top: 1px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}

.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
