<template>
  <div v-loading.fullscreen.lock="busy">
    <div>
      <date-picker
        class="date-picker"
        :date-range.sync="dateRange"
        open-start-date
        :to="new Date()"
      />
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :find-options="findOptions"
      :data-class="reportModel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :pagination-size="dataTable.pageSize"
      disable-checkboxes
      disable-action-menu
      disable-search
    >
      <el-table-column label="Changed Item">
        <el-table-column
          label="Type"
          width="100"
        >
          <template slot-scope="{ row }">
            {{ row.model }}
          </template>
        </el-table-column>
        <el-table-column
          label="ID"
          width="100"
        >
          <template slot-scope="{ row }">
            {{ row.model_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Name"
          width="140"
        >
          <template slot-scope="{ row }">
            {{ row.target_name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="organization"
          label="Organization"
          width="140"
        >
          <template slot-scope="{ row }">
            {{ row.o_name }}
          </template>
        </el-table-column>
        <el-table-column label="Added">
          <template slot-scope="{ row }">
            <div class="flex">
              <p>{{ row.changes.added }}</p>
              <json-viewer
                :data="row.changes.added"
                title="Added"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Updated">
          <template slot-scope="{ row }">
            <div class="flex">
              <p>{{ row.changes.updated }}</p>
              <json-viewer
                :data="row.changes.updated"
                title="Updated"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Deleted">
          <template slot-scope="{ row }">
            <div class="flex">
              <p>{{ row.changes.deleted }}</p>
              <json-viewer
                :data="row.changes.deleted"
                title="Deleted"
              />
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="activity"
        label="Activity"
        sortable="custom"
        width="140"
      />
      <el-table-column
        prop="user"
        label="User"
        sortable="custom"
        width="140"
      >
        <template slot-scope="{ row }">
          {{ row.author }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Timestamp"
        sortable="custom"
        width="120"
      >
        <template slot-scope="{ row }">
          {{ row.created_at | dateToString({ formatOverride: 'YYYY-MM-DD h:mma' }) }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { dateToString } from '@/helpers';
import { userActivityReport } from '@/adonis-api';
import DataTable from '@/components/global/DataTable.vue';
import DatePicker from '@/components/global/DatePicker.vue';
import JsonViewer from '@/components/global/JsonViewer.vue';

import moment from 'moment';

export default {
  components: { DataTable, DatePicker, JsonViewer },
  filters: { dateToString },

  data() {
    return {
      busy: false,
      dateRange: [
        moment().utc().subtract(1, 'week'),
        moment().utc(),
      ],
      dataTable: {
        actions: [],
        order: 'descending',
        sortBy: 'created_at',
        pageSize: 10,
      },
      reportModel: null,
    };
  },

  computed: {
    findOptions() {
      return {
        start: moment(this.dateRange[0]).utc().format('YYYY-MM-DD'),
        end: moment(this.dateRange[1]).utc().format('YYYY-MM-DD'),
      };
    },
  },

  created() {
    this.reportModel = userActivityReport;
  },

  // methods: {
  //   async downloadCsv() {
  //     this.preparingDownload = true;

  //     const result = await userActivityReport.find({ ...this.findOptions, csv: true });

  //     downloadFile(result, {
  //       filename: `Geoframe-Creation-Report-${new Date().toISOString().slice(0, 10)}.csv`,
  //       mimeType: 'text/csv',
  //       prettify: false,
  //     });

  //     this.preparingDownload = false;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.date-picker {
  display: inline-block;
  margin: 0 1em 1em 0;
  position: relative;
  top: 1px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}

.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
