<template>
  <div>
    <el-tooltip
      v-if="data"
      content="View Data"
      :open-delay="500"
      placement="top"
    >
      <span
        class="fa-stack btn-enabled"
        title="View Data"
        @click="showJson = true"
      >
        <i class="fa fa-square fa-stack-2x" />
        <i class="fa fa-brackets-curly fa-stack-1x fa-inverse" />
      </span>
    </el-tooltip>
    <span
      v-else
      class="fa-stack btn-disabled"
    >
      <i class="fa fa-square fa-stack-2x" />
      <i class="fa fa-brackets-curly fa-stack-1x fa-inverse" />
    </span>

    <el-dialog
      :title="title"
      :visible.sync="showJson"
      width="75%"
    >
      <div class="copy-button">
        <el-button
          v-clipboard:copy="prettyJson"
          v-clipboard:success="afterCopy"
          icon="fa fa-clipboard-list"
          size="small"
          type="primary"
        >
          Copy to Clipboard
        </el-button>
      </div>
      <tree-view :data="data" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
    title: { type: String, required: true },
  },

  data() {
    return {
      showJson: false,
    };
  },

  computed: {
    prettyJson() {
      try {
        return JSON.stringify(this.data, null, 2);
      } catch (e) {
        return JSON.stringify({
          error: 'Error prettifying JSON',
        });
      }
    },
  },

  methods: {
    afterCopy() {
      this.$message({
        message: 'JSON copied to clipboard.',
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-enabled {
  cursor: pointer;
}

.btn-disabled {
  opacity: 0.5;
}

:deep( .tree-view-wrapper) {
  overflow: visible !important;
}

:deep( .tree-view-item-leaf) {
  white-space: normal !important;
}

.copy-button {
  margin-bottom: 1em;
}
</style>
